import * as React from "react";
import { Container, Header, List, Image, Card, Grid, GridColumn, Segment } from "semantic-ui-react";
import Title from "../components/Title";
import { withLayout } from "../components/Layout";
import XIcon  from "../components/XIcon";

const pageTitle = "About us";

const AboutPage: React.FC = () => {
  return (
    <Container className="bg-sabo">
      {/* Header */}
      <Title title={pageTitle} />

      <Header as="h3">Cybozu Techについて</Header>
      サイボウズ株式会社 開発本部 コネクト支援チームが運営しています。<br/>
      サイボウズのエンジニアのアウトプットが集まり、少しでも皆様のお役に立ち、サイボウズが面白いことをやってるなと思ってもらえれば幸いです。

      <Header as="h3">コネクト支援チーム</Header>
      名前の由来ですが、「社内のエンジニアと社外のエンジニアのコネクトを支援する」ということを目的に様々な活動をしています。<br/>
      この「Cybozu Tech」はその活動のひとつです。<br/><br/>

      <iframe title="connect-support-team" src="https://www.docswell.com/slide/KM1J1X/embed?key=connect-support-team" allowFullScreen className="docswell-iframe" width="620" height="349"></iframe>

      <Segment vertical padded="very">
      <Header as="h4">メンバー（順不同）</Header>
      <Grid columns={4} doubling stackable>
        <GridColumn>
          <Card>
            <Image src="/images/members/sakay_y.jpg" />
            <Card.Content>
              <Card.Header>SAKAI Yasuharu</Card.Header>
              <Card.Description>
                <List>
                  <List.Item>
                    <List.Icon>
                      <XIcon/>
                    </List.Icon>
                    <List.Content><a target="_blank" rel="noreferrer noopener" href="https:/x.com/sakay_y">@sakay_y</a></List.Content>
                  </List.Item>
                </List>
              </Card.Description>
            </Card.Content>
          </Card>
        </GridColumn>
        <GridColumn>
          <Card>
            <Image src="/images/members/jnkykn.jpg" />
            <Card.Content>
              <Card.Header>KIJIMA Junko</Card.Header>
              <Card.Description>
                <List>
                  <List.Item>
                    <List.Icon>
                      <XIcon/>
                    </List.Icon>
                    <List.Content><a target="_blank" rel="noreferrer noopener" href="https://x.com/jnkykn">@jnkykn</a></List.Content>
                  </List.Item>
                </List>
              </Card.Description>
            </Card.Content>
          </Card>
        </GridColumn>
        <GridColumn>
          <Card>
            <Image src="/images/members/chacha-maru.jpeg" />
            <Card.Content>
              <Card.Header>MARUYAMA Nami</Card.Header>
              <Card.Description>
                <List>
                  <List.Item>
                    <List.Content>MARUYAMA Nami</List.Content>
                  </List.Item>
                </List>
              </Card.Description>
            </Card.Content>
          </Card>
        </GridColumn>
        <GridColumn>
          <Card>
            <Image src="/images/members/sabotech.png" />
            <Card.Content>
              <Card.Header>さぼてっく</Card.Header>
              <Card.Description>Cybozu Techのマスコットキャラクター。サボテン。</Card.Description>
            </Card.Content>
          </Card>
        </GridColumn>
      </Grid>
      </Segment>
    </Container>
  );
};

export default withLayout(AboutPage, pageTitle);
